* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@700&display=swap");

:root {
  font-size: 16px;

  /* Colour Variables */
  --clr-primary: hsl(13, 88%, 55%);
  --clr-secondary: hsl(0, 0%, 100%);
  --clr-contrast: hsl(0, 8%, 10%);

  /* Font faces */
  --ff-text: "Barlow Condensed", sans-serif;
}

@keyframes load {
  from {
    border: none;
    background: var(--clr-primary);
    color: var(--clr-contrast);
  }
  to {
    border: 1px solid var(--clr-primary);
    background: var(--clr-contrast);
    color: var(--clr-primary);
  }
}

.App {
  background: var(--clr-contrast);
}

/* Landing Page */

#landing__container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10%;
}

#landing__content-container {
  height: 300px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
}

#text__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
}

#main__title {
  font-family: var(--ff-text);
  font-size: 4rem;
  color: var(--clr-primary);
  animation: slide-top 2s;
}

#main__title-container {
  display: flex;
  flex-direction: row;
}

#end__title {
  font-family: var(--ff-text);
  font-size: 4rem;
  color: var(--clr-secondary);
}

#main__text-container {
  font-family: var(--ff-text);
  font-size: 1.5rem;
  color: var(--clr-secondary);
}

/* Button Styling */

#contact {
  background: var(--clr-primary);
  border: none;
  height: 50px;
  min-width: 175px;
  width: 30%;
  max-width: 200px;
  font-family: var(--ff-text);
  font-size: 1.5rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-contrast);
  position: absolute;
  bottom: 25%;
}

#contact:hover {
  cursor: pointer;
  animation: load;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Responsive Mobile */

@media (max-width: 480px) {
  #main__title,
  #end__title {
    font-size: 3.5rem;
  }

  #contact {
    height: 45px;
    width: 25%;
  }
}
